import { Injectable, OnDestroy } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { TokenService } from './token.service';
import { updateUserData } from '../ngrx/data.action';
import { selectUser } from '../ngrx/data.reducer';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppSignalRService implements OnDestroy {
  private hubConnection: signalR.HubConnection;
  public isConnected: boolean = false;
  user$ = this.store.select(selectUser);
  userDetails: any;
  private destroy$ = new Subject<void>();
  private shownNotifications = new Set<string>();

  constructor(
    private store: Store,
    private tokenService: TokenService,
    private router: Router,
  ) {
    this.user$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      )
      .subscribe((user) => {
        this.userDetails = user;
        if (this.isConnected) {
          this.disconnect();
          this.startConnection().subscribe();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.disconnect();
  }

  private buildConnection(): void {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}chatHub`, {
        accessTokenFactory: () => this.userDetails?.token,
      })
      .withAutomaticReconnect()
      .build();
  }

  startConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.hubConnection) {
        if (
          this.hubConnection.state === signalR.HubConnectionState.Disconnected
        ) {
          this.hubConnection
            .start()
            .then(() => {
              console.log('Connection established with SignalR hub');
              this.isConnected = true;
              observer.next();
              observer.complete();
            })
            .catch((error) => {
              this.isConnected = false;
              console.error('Error connecting to SignalR hub:', error);
              if (error.message.includes('401')) {
                this.refreshTokenAndReconnect(observer);
              } else {
                observer.error(error);
              }
            });
        } else {
          console.warn('Connection is already in progress or established');
          observer.next();
          observer.complete();
        }
      } else {
        this.buildConnection();
        this.startConnection().subscribe();
      }
    });
  }

  disconnect(): void {
    if (this.isConnected) {
      this.hubConnection
        .stop()
        .then(() => {
          console.log('Disconnected from SignalR hub');
          this.isConnected = false;
        })
        .catch((error) => {
          console.error('Error disconnecting from SignalR hub:', error);
        });
    }
  }

  receiveMessage(): Observable<any> {
    return new Observable<any>((observer) => {
      if (this.hubConnection) {
        this.hubConnection.on('ReceiveMessage', (userName, message) => {
          const data = {
            username: userName,
            message: message,
            contentType: 'text',
            file: null,
          };
          const updateMessage =
            'Sent you a message ( ' +
            (message.length > 10
              ? message.substring(0, 10) + '... )'
              : message + ' )');
          if (!this.shownNotifications.has(updateMessage)) {
            this.showNotification(userName, updateMessage);
            this.shownNotifications.add(updateMessage);
          }
          observer.next(data);
        });
      }
    });
  }

  ReceiveFileEvent(): Observable<any> {
    return new Observable<any>((observer) => {
      if (this.hubConnection) {
        this.hubConnection.on(
          'ReceiveFile',
          (userName, message, filePath, contentType) => {
            const data = {
              username: userName,
              message: message,
              contentType: contentType,
              file: filePath,
            };
            const notificationMessage =
              contentType === 'audio'
                ? 'Sent you an audio message'
                : contentType === 'video'
                  ? 'Sent you a video message'
                  : 'Sent you a pdf file';
            if (!this.shownNotifications.has(notificationMessage)) {
              this.showNotification(userName, notificationMessage);
              this.shownNotifications.add(notificationMessage);
            }
            observer.next(data);
          },
        );
      }
    });
  }

  SendMessageEvent(
    userId: string,
    contactId: string,
    img: string,
    message: string,
  ) {
    this.hubConnection?.invoke(
      'SendMessage',
      userId,
      `${this.userDetails?.fullName}&${contactId}&${img}`,
      message,
    );
  }
  AllowAttachmentEvent(userId, userName, status) {
    this.hubConnection?.invoke(
      'allowAttachment',
      JSON.stringify(userId),
      userName,
      status,
    );
  }
  AttachmentStatusEvent(): Observable<any> {
    return new Observable<any>((observer) => {
      if (this.hubConnection) {
        this.hubConnection.on(
          'attachmentStatus',
          (userName, status, userId) => {
            const data = {
              username: userName,
              userId: userId,
              status: status,
            };
            observer.next(data);
          },
        );
      }
    });
  }

  SendFileEvent(
    userId: string,
    contactId: string,
    img: string,
    filePath: string,
    contentType: string,
  ) {
    this.hubConnection?.invoke(
      'SendFile',
      userId,
      `${this.userDetails?.fullName}&${contactId}&${img}`,
      null,
      filePath,
      contentType,
    );
  }

  async showNotification(userName: string, message: string) {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      const permission = await Notification.requestPermission();
      if (
        permission === 'granted' &&
        !['/creator/chat', '/fan/chat'].includes(this.router.url)
      ) {
        const registration = await navigator.serviceWorker.ready;
        registration.showNotification(userName.split('&')?.[0], {
          body: message,
          icon: userName.split('&')?.[2],
          data: {
            url: '/chat',
            fullName: userName.split('&')?.[0],
            id: userName.split('&')?.[1],
          },
        });
      } else {
        console.log('Notification permission denied.');
      }
    } else {
      console.log('Service workers or push not supported in this browser.');
    }
  }

  private refreshTokenAndReconnect(observer: any) {
    this.tokenService
      .refreshToken()
      .pipe(
        switchMap((data) => {
          const updatedUserDetails = {
            ...this.userDetails,
            token: data.token,
            refreshToken: data.refreshToken,
          };
          this.store.dispatch(updateUserData({ user: updatedUserDetails }));
          this.userDetails = updatedUserDetails;
          this.buildConnection();
          return this.hubConnection.start();
        }),
        catchError((refreshError) => {
          console.error('Error during token refresh:', refreshError);
          observer.error(refreshError);
          return [];
        }),
      )
      .subscribe({
        next: () => {
          console.log('Reconnected with new token');
          this.isConnected = true;
          observer.next();
          observer.complete();
        },
        error: (error) => {
          console.error('Error reconnecting with new token:', error);
          observer.error(error);
        },
      });
  }
}
