import { Injectable } from '@angular/core';

@Injectable()
export class ProductService {
  returnProductsJSON() {
    const products = [
      {
        id: 2,
        title: 'One-One and Group Video Calls',
        subtitle: 'Personalized, Interactive and Live Experiences',
        description:
          'Now you can offer your followers paid video calls. Set your availability on your terms and convert your sessions into valuable content by sharing them after.',
        image:
          'https://res.cloudinary.com/dmdawjgs8/image/upload/v1724761842/reemkiz4jtuy9gtup2eu.webp',
        left: true,
      },
      {
        id: 1,
        title: 'Every Single Chat Gets You Paid',
        subtitle: 'Interact the Way You Like',
        description:
          'Turn your inbox into income with Mentoga. Every message you receive gets you paid; you can reply with text, voice, or video chats.',
        image:
          'https://res.cloudinary.com/dmdawjgs8/image/upload/v1732619719/epcpwg7wyxj2no39k6jf.webp',
          left: false,
      },
      {
        id: 3,
        title: 'Create Your VVIP Circle',
        subtitle: 'Engage Your Fans',
        description:
          "Allow your most avid fans to join your inner circle by paying a monthly subscription fee. We make it ridiculously easy.",
        image:
          'https://res.cloudinary.com/dmdawjgs8/image/upload/v1724761868/c4i9pjqv3v3spn3kzigt.webp',
        left: true,
      },
      {
        id: 4,
        title: 'Let Them Tip You When They Want',
        subtitle: 'Feel the Love',
        description:
          "Let fans support your work by tipping you whenever they want, just for being you.",
        image:
          'https://res.cloudinary.com/dmdawjgs8/image/upload/v1716286491/comejoinus/Frame_1261152600_1_t37gqe.webp',
        left: false,
      },
    ];
    return products;
  }
}
