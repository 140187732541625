<div
  [id]="modalId"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
>
  <div
    [ngClass]="[
      modalSize ?? '',
      verticallyCentered ? 'modal-dialog-centered' : ''
    ]"
    class="modal-dialog"
    role="document"
  >
    <div class="modal-content rounded-4" [style.border-radius]="radius ?? ''">
      <ng-content></ng-content>
    </div>
  </div>
</div>
