import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { setIpState } from './ngrx/data.action';
import { selectSideBar, selectSlider, selectUser } from './ngrx/data.reducer';
import { HelperService } from './services/helper.service';
import { LoaderService } from './services/loader.service';
import { ModalService } from './services/modal.service';
import { ProductService } from './services/product.service';
import { ResizeService } from './services/resize.service';
import { ScrollService } from './services/scroll.service';
import { AppSignalRService } from './services/signalR.service';
import { HeadlineComponent } from './shared/headline/headline.component';
import { LoadingComponent } from './shared/loaders/loading/loading.component';
import { ModalComponent } from './shared/modals/modal/modal.component';
import { activeSlideAnimation } from './tsExtras/activeSlideAnimation';
import { OnlineStatusService } from './services/OnlinStatus.service';

export const appVersion = '2024.12.10.2.0';

declare var WOW: any;

@Component({
  selector: 'app-root',
  standalone: true,
  animations: [activeSlideAnimation],
  imports: [
    CommonModule,
    RouterOutlet,
    LoadingComponent,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    LazyLoadImageModule,
    ModalComponent,
    FontAwesomeModule,
    HeadlineComponent,
    CarouselModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  faGlobe = faGlobe;
  sideBar = false;
  userDetails;
  loggedIn = false;
  logoutModal = false;
  loader = true;
  route: string;
  auth = false;
  progressBar = false;
  scrollTop = 0;
  status: boolean = true;
  connectionType: string;
  products;

  appVersionLocalStorage = localStorage.getItem('appVersion');

  productsOWL: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    autoplay: true,
    navSpeed: 700,
    center: true,
    responsive: {
      0: { items: 1 },
    },
    nav: false,
  };
  private destroy$ = new Subject<void>();
  modalVersion = false;

  constructor(
    public resize: ResizeService,
    private store: Store,
    public helper: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private scrollService: ScrollService,
    private modalService: ModalService,
    private onlineStatusService: OnlineStatusService,
    private updates: SwUpdate,
    private signalRService: AppSignalRService,
    private cd: ChangeDetectorRef,
    private productService: ProductService,
  ) {
    if (this.appVersionLocalStorage !== appVersion) {
      this.modalVersion = true;
    }
    this.initializeSubscriptions();
    this.checkForUpdates();
    this.products = this.productService.returnProductsJSON();
    this.observeLoader();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.resize.onResize();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;
    const scrollUp = currentScrollTop < this.scrollTop;
    this.scrollTop = currentScrollTop;
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.scrollService.onScroll(scrollPosition, scrollUp);
  }

  ngOnInit() {
    this.updateHeaderState(this.activatedRoute.root);
    this.helper
      .getCountryCodeDefault()
      .toPromise()
      .then((ip: any) => {
        this.store.dispatch(setIpState({ ip }));
      });
  }

  ngAfterViewInit(): void {
    new WOW().init();
    setTimeout(() => {
      if (environment.production) {
        this.helper.addClarityScript();
        this.helper.runGTagScript();
        this.helper.runSearchTearmScript();
      }
    }, 20000);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout() {
    this.helper.signOut();
  }

  private initializeSubscriptions() {
    this.subscribeToOnlineStatus();
    this.subscribeToRouterEvents();
    this.subscribeToModalService();
    this.subscribeToStore();
  }

  private subscribeToOnlineStatus() {
    this.status = this.onlineStatusService.isOnline;

    if ((navigator as any).connection) {
      (navigator as any).connection.addEventListener('change', (event) => {
        this.initService();
      });
    }
  }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationError),
        map((event) => event as NavigationError),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        if (
          event.error instanceof Error &&
          event.error.name === 'ChunkLoadError'
        ) {
          window.location.assign(event.url);
        }
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationStart) => {
        this.initService();        
        if (event?.url === '/signup' || event?.url === '/fan/dashboard' ) {}
        else {
          LoaderService.loader.next(true);
        }
        if (document.body.classList.contains('sideBarOpenBody')) {
          this.helper.hideModal();
        }
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationEnd) => {
        this.initService();
        this.route = event.url;
        this.updateHeaderState(this.activatedRoute.root);
        LoaderService.loader.next(false);
      });
  }

  private subscribeToModalService() {
    this.modalService
      .getModalStateObservable()
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((state: any) => {
        if (state.id ==='signUpUrlModal' && !state.show) {
          this.auth = false;
        }        
        this[state.id] = state.show;
      });
  }

  private subscribeToStore() {
    this.store
      .select(selectSideBar)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((open) => {
        if (open) {
          document.body.classList.add('sideBarOpenBody');
        } else {
          document.body.classList.remove('sideBarOpenBody');
        }
        document
          .querySelector('.scrollContainer')
          ?.classList.toggle('overflow-hidden');
        if (open) {
          setTimeout(() => {
            document.querySelector('.scrollContainer')?.classList.add('vh-100');
          }, 500);
        } else {
          setTimeout(() => {
            document
              .querySelector('.scrollContainer')
              ?.classList.remove('vh-100');
          }, 500);
        }
      });

    this.store
      .select(selectSlider)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        document.body.classList.toggle('sideBarOpenBody');
      });

    this.store
      .select(selectUser)
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((user) => {
        if (
          user &&
          ['fan', 'creator', 'both'].includes(user?.userType?.toLowerCase())
        ) {
          this.userDetails = user;
          this.loggedIn = true;
          if (!this.signalRService.isConnected) {
            this.startSignalRConnection();
          }
        } else {
          this.userDetails = null;
          this.signalRService.disconnect();
          this.loggedIn = false;
        }
      });
  }

  private startSignalRConnection() {
    this.signalRService
      .startConnection()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.signalRService
          .receiveMessage()
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log(data);
          });
        this.signalRService
          .ReceiveFileEvent()
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            console.log(data);
          });
      });

    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'NOTIFICATION_CLICK') {
        const url = event.data.data.notification.data.url;
        const id = event.data.data.notification.data.id;
        localStorage.setItem('contactId', id);
        if (url) {
          this.navigateToUrl(url);
        }
      }
    });
  }

  private navigateToUrl(url: string) {
    const fullUrl = this.getFullUrl(url);
    if (document.visibilityState === 'visible') {
      this.router.navigate([fullUrl]);
    }
  }

  private getFullUrl(url: string): string {
    return this.userDetails?.userType?.toLowerCase() === 'creator'
      ? `/creator${url}`
      : `/fan${url}`;
  }

  private checkForUpdates() {
    this.updates.versionUpdates
      .pipe(
        takeUntil(this.destroy$),
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe(() => {
        if (this.appVersionLocalStorage !== appVersion) {
          this.modalVersion = true;
        }
      });
  }


  private updateHeaderState(route: any): void {
    while (route.firstChild) {
      route = route.firstChild;
    }
    const data = route.snapshot.data;
    this.auth = data?.auth || false;
    this.progressBar = data?.progressBar || false;
    LoaderService.loader.next(false);
  }

  private initService() {
    const conn = (navigator as any).connection;
    if (conn) {
      this.connectionType = conn.effectiveType;
    }
  }

  public updateVersion(): void {
    this.modalVersion = false;
    document.location.reload();
  }

  public closeVersion(): void {
    this.modalVersion = false;
  }

  private observeLoader() {
    LoaderService.loader.subscribe((res: any) => {
      this.loader = res;
      if (this.loader) {
        document.body.classList.add('vh-100', 'vw-100', 'overflow-hidden');
      } else {
        document.body.classList.remove('vh-100', 'vw-100', 'overflow-hidden');
      }
      this.cd.detectChanges();
    });
  }
}
