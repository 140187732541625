import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import moment from 'moment';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs';
import { environment } from '../../environments/environment';
import { appVersion } from '../app.component';
import {
  addCarouselData,
  addFlowData,
  removeUserData,
  toggleSlider,
} from '../ngrx/data.action';
import { selectIp, selectUser } from '../ngrx/data.reducer';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { LoaderService } from './loader.service';
import { ModalService } from './modal.service';
declare var $: any;

export const whiteLogo =
  'assets/images/mentogaLogo.png';
export const whiteSmallLogo =
  'assets/images/mentogaLogo.png';
export const blueSmallLogo =
  'assets/images/mentogaLogo.png';
export const blueLogo =
  'assets/images/mentogaLogo.png';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  user$ = this.store.select(selectUser);
  userDetails;
  apiUrl: any = environment.apiUrl;
  ip$ = this.store.select(selectIp);
  ipData;
  private hubConnection: signalR.HubConnection;
  AppleConfig = {
    clientId: 'com.mentoga.angularExample',
    scope: 'email name',
    usePopup: true,
    redirectURI: 'https://mentoga.com/signin',
  };
  private destroy$ = new Subject<void>();
  constructor(
    private auth: AuthService,
    private store: Store,
    private route: Router,
    private authService: SocialAuthService,
    private http: HttpClient,
    private httpService: HttpService,
    private ModalService: ModalService,
  ) {
    this.user$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((user) => {
        this.userDetails = user;
      });
    this.ip$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((ip: any) => {
        this.ipData = ip;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  formatTo24Hour = (datetime) => {
    var hours = Number(datetime.match(/^(\d+)/)[1]);
    var minutes = Number(datetime.match(/:(\d+)/)[1]);
    var AMPM = datetime.match(/\s(.*)$/)[1];
    if (AMPM == 'PM' && hours < 12) hours = hours + 12;
    if (AMPM == 'AM' && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = '0' + sHours;
    if (minutes < 10) sMinutes = '0' + sMinutes;
    return sHours + ':' + sMinutes;
  };

  getLocalTime = (time, date) => {
    if (time && date) {
      const res = this.formatTo24Hour(time);
      var date: any = moment.utc().format(`${date} ${res}:00`);
      var stillUtc = moment.utc(date).toDate();
      var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
      let formatedDate = moment(local).format('h:mm A');
      return formatedDate;
    } else {
      return '';
    }
  };

  logoutSocial() {
    try {
      this.authService.signOut();
    } catch (error) {
      // catch error
    }
  }

  async signOut() {
    await LoaderService.loader.next(true);
    var versionClear = localStorage.getItem('versionClear');
    var redirectPath = localStorage.getItem('collectionRedirectPath');
    var themeDetails = localStorage.getItem('themeDetails');
    localStorage.clear();
    localStorage.setItem('versionClear', versionClear);
    localStorage.setItem('collectionRedirectPath', redirectPath);
    if (themeDetails) {
      localStorage.setItem('themeDetails', themeDetails);
    }
    await this.route.navigateByUrl('/');
    await this.auth.logout();
    
    // logout social account
    this.logoutSocial()
    
    await this.store.dispatch(removeUserData());
    await LoaderService.loader.next(false);
  }
  checkStringAgainstPaths(arr, str) {
    for (let index = 0; index < arr.length; index++) {
      if (arr[index]?.path == str) {
        return false;
      }
    }
    return true;
  }
  getCountryCodeDefault(): Observable<string> {
    return this.http
      .get('https://get.geojs.io/v1/ip/country.json')
      .pipe(map((response: any) => response));
  }
  openModal() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      document.querySelector('.buyer')?.classList.add('open');
      this.store.dispatch(toggleSlider({ open: true }));
    }, 100);
  }
  getOnBoarding() {
    this.httpService
      .loaderGet('api/User/on-boarding-completion-web', true, true)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      )
      .subscribe((response) => {
        this.store.dispatch(addFlowData({ flow: response?.model }));
        LoaderService.loader.next(false);
      });
  }
  detectBrowserName() {
    const userAgent = navigator.userAgent;
    if (/Edg\/\d+/.test(userAgent)) {
      return 'Microsoft Edge';
    }

    if (/MSIE/i.test(userAgent) || /Trident/i.test(userAgent)) {
      return 'Internet Explorer';
    }

    if (/Opera/i.test(userAgent) || /OPR/i.test(userAgent)) {
      return 'Opera';
    }

    if (/Chrome/i.test(userAgent)) {
      return 'Google Chrome';
    }

    if (/Firefox/i.test(userAgent)) {
      return 'Mozilla Firefox';
    }

    if (/Safari/i.test(userAgent)) {
      return 'Apple Safari';
    }

    return 'Unknown';
  }
  detectOS() {
    const userAgent = navigator.userAgent;
    if (/Windows NT 10.0/.test(userAgent)) {
      return 'Windows 10';
    }

    if (/Windows NT 6.3/.test(userAgent)) {
      return 'Windows 8.1';
    }

    if (/Windows NT 6.2/.test(userAgent)) {
      return 'Windows 8';
    }

    if (/Windows NT 6.1/.test(userAgent)) {
      return 'Windows 7';
    }

    if (/Windows NT 6.0/.test(userAgent)) {
      return 'Windows Vista';
    }

    if (/Windows NT 5.1/.test(userAgent)) {
      return 'Windows XP';
    }

    if (/Android/.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    if (/Macintosh|Mac OS X/.test(userAgent)) {
      return 'Mac OS';
    }

    if (/Linux/.test(userAgent)) {
      return 'Linux';
    }

    return 'Unknown';
  }

  profileImageUpload(selectedFile: any, token: any): Observable<any> {
    return new Observable((observer) => {
      const url = `${environment.apiUrl}api/User/upload-user-image`;
      const formData = new FormData();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob((blob) => {
          formData.append('file', blob, 'profileImage.webp');
          const headers = new HttpHeaders().set(
            'Authorization',
            `Bearer ${token}`,
          );

          this.http
            .post(url, formData, { headers })
            .pipe(
              distinctUntilChanged(
                (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
              ),
              takeUntil(this.destroy$),
            )
            .subscribe(
              (response: any) => {
                if (response.status) {
                  observer.next(response);
                  observer.complete();
                }
              },
              (error: any) => {
                // Handle error
                console.error('Error uploading profile image:', error);
                observer.error(error);
              },
            );
        }, 'image/webp');
      };

      // Set the source of the image
      img.src = URL.createObjectURL(selectedFile);

      // Cleanup on unsubscribe
      return () => {
        URL.revokeObjectURL(img.src);
      };
    });
  }
  uploadIntroVideo(video: any, token: any) {
    let url = `${environment.apiUrl}api/User/upload-user-video`;
    let formData = new FormData();
    formData.append('file', video);
    const TypeOf = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
    };
    return this.http.post(url, formData, TypeOf);
  }
  uploadImageToCloudinary(data) {
    return this.http.post(
      `${environment.apiUrl}api/home/upload-cloudinary-image`,
      data,
    );
  }
  iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ]?.includes(navigator.platform) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  scrollToTop() {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
    return false;
  }
  showModal(show: boolean, id: string) {
    if (show) {
      this.ModalService.showModal(id);
      setTimeout(() => {
        $('#' + id).modal('show');
      }, 100);
    } else {
      $('#' + id).modal('hide');
      setTimeout(() => {
        this.ModalService.hideModal(id);
      }, 100);
    }
  }
  hideModal() {
    document.querySelector('.buyer')?.classList.remove('open');
    document.querySelector('.buyer')?.classList.add('closed');
    this.store.dispatch(toggleSlider({ open: false }));
    document.querySelector('.buyer')?.classList.remove('closed');
  }
  getProcessedPhone(phone: any): string {
    return typeof phone === 'object' && phone !== null ? phone.number : phone;
  }
  removeGuestUser() {
    const guestUser = localStorage.getItem('guestUser');
    if (guestUser) {
      localStorage.removeItem('userDetails');
      this.store.dispatch(removeUserData());
    }
  }
  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
  addClarityScript() {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "m2laqb1oqe");
    `;
    document.head.appendChild(script);
  }
  runWatiScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'watiScript';
    script.text = `
      var url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?60878';
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = url;
      var options = {
        "enabled": true,
        "chatButtonSetting": {
          "backgroundColor": "#00b368",
          "ctaText": "",
          "borderRadius": "25",
          "marginLeft": "0",
          "marginRight": "20",
          "marginBottom": "20",
          "ctaIconWATI": false,
          "position": "right"
        },
        "brandSetting": {
          "brandName": "Mentoga",
          "brandSubTitle": "undefined",
          "brandImg": "https://res.cloudinary.com/dmdawjgs8/image/upload/f_auto,q_auto/fdizzkyh8nddlb4wyuij",
          "welcomeText": "Hi there!\\nHow can I help you?",
          "messageText": "",
          "backgroundColor": "#00b368",
          "ctaText": "",
          "borderRadius": "25",
          "autoShow": false,
          "phoneNumber": "19177303753"
        }
      };
      s.onload = function () {
        CreateWhatsappChatWidget(options);
        setTimeout(function() {
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.wa-widget-send-button { z-index: 1000 !important; }';
        document.head.appendChild(style);
      }, 3000);
      };
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    `;
    document.head.appendChild(script);
  }
  runGTagScript() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-B7QVMLFS74';
    document.head.appendChild(script);

    const configScript = document.createElement('script');
    configScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());

      gtag('config', 'G-B7QVMLFS74');
    `;
    document.head.appendChild(configScript);
  }
  runSearchTearmScript() {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = `
    {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "Mentoga",
      "alternateName": "Mentoga",
      "url": "https://mentoga.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": {
          "@type": "EntryPoint",
          "urlTemplate": "https://mentoga.com/creators/{search_term_string}"
        },
        "query-input": "required name=search_term_string"
      }
    }
  `;
    document.head.appendChild(script);
  }
  convertUTCToLocalTime(utcTimeString: string): string {
    const date = new Date(utcTimeString);
    return date.toLocaleString();
  }
  formatMessagesByDate(messages) {
    const groupedMessages = {};
    messages.forEach((message) => {
      const createdDate = new Date(message.createdOn).toDateString();
      if (!groupedMessages[createdDate]) {
        groupedMessages[createdDate] = [];
      }
      groupedMessages[createdDate].push(message);
    });
    const formattedMessages = Object.keys(groupedMessages).map((date) => {
      return { date, messages: groupedMessages[date] };
    });
    return formattedMessages;
  }
  pushInTodaysArray(chatDetails, data) {
    const formattedDate = moment(data?.createdOn).format('ddd MMM DD YYYY');
    let dateFound = false;

    chatDetails.forEach((dateObj) => {
      if (dateObj.date === formattedDate) {
        dateObj.messages.push(data);
        dateFound = true;
      }
    });

    if (!dateFound) {
      chatDetails.push({
        date: formattedDate,
        messages: [data],
      });
    }
  }
  animateScroll(
    element: HTMLElement,
    from: number,
    to: number,
    duration: number,
  ) {
    const startTime = new Date().getTime();
    const animation = () => {
      const currentTime = new Date().getTime();
      const progress = Math.min((currentTime - startTime) / duration, 1);
      const easeInOutQuad = (t: number) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      element.scrollTop = from + (to - from) * easeInOutQuad(progress);
      if (progress < 1) {
        requestAnimationFrame(animation);
      }
    };
    requestAnimationFrame(animation);
  }
  public capitalizeFirstLetter(str: string): string {
    return str
      ? str
          .split(' ')
          .map(
            (word) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
          )
          .join(' ')
      : '';
  }
  returnExtraDetails() {
    const data = {
      ip: this.ipData?.ip,
      os: this.detectOS(),
      version: appVersion,
      source: 'Browser',
      device: this.capitalizeFirstLetter(this.detectOS()),
      browser: this.capitalizeFirstLetter(this.detectBrowserName()),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    return data;
  }
  async getCarouselData() {
    await this.httpService
      .alternateLoaderGet('api/home/get-carousel-data', false, false)
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      )
      .subscribe(async (response: any) => {
        const newArray = (response.objModelList || []).map((model) => {
          const newObject1 = {
            categoryName: model?.[0]?.categoryName,
            showAll: true,
          };
          const newObject2 = {
            dummy: true,
          };
          const additionalObjects =
            window.innerWidth > 520 ? [newObject2, newObject2] : [];
          return model?.length
            ? [...model, newObject1, ...additionalObjects]
            : [];
        });
        this.store.dispatch(addCarouselData({ carousel: newArray }));
      });
  }
  async shareImage(dataUrl: string) {
    if (!navigator.share || !navigator.canShare) {
      console.error('Web Share API is not supported in your browser.');
      alert('Web Share API is not supported in your browser.');
      return;
    }

    const blob = this.dataURItoBlob(dataUrl);
    const file = new File([blob], 'screenshot.png', { type: 'image/png' });

    const data = {
      title:
        'I am now on Mentoga. If you want to talk to me directly Click on the link in my Bio.',
      files: [file],
    };

    if (!navigator.canShare(data)) {
      console.error('The data is unsharable, check data');
      alert('The data is unsharable, check data');
      return;
    }

    let shared = false;
    let attempts = 0;
    const maxAttempts = 10;
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    while (!shared && attempts < maxAttempts) {
      try {
        await navigator.share(data);
        console.log('Shared successfully');
        shared = true;
        LoaderService.loader.next(false);
      } catch (error) {
        console.error('Sharing failed', error);
        if (error.message === 'share canceled') {
          break;
        }
        alert(`Sharing failed: ${error.message}`);
        attempts++;
        if (attempts < maxAttempts) {
          console.log(`Retrying... (${attempts}/${maxAttempts})`);
          await delay(2000);
        } else {
          LoaderService.loader.next(false);
        }
      }
    }

    if (!shared) {
      alert('Failed to share after multiple attempts.');
    }
  }

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  copyMessage(element, url) {
    navigator.clipboard
      .writeText(url)
      .then()
      .catch((e) => {});
    setTimeout(() => {
      element.close();
    }, 1000);
  }
}
