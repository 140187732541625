@if (!status) {
  <div class="status" [class.offline]="!status">
    <p class="text-center mb-0">
      <fa-icon [icon]="faGlobe"></fa-icon>
      No internet connection detected.
    </p>
  </div>
}
@if (modalVersion) {
  <div class="w-100 position-absolute zIndex10 top-0">
    <div class="alert alert-secondary m-2">
      A new version of this app is available.
      <a class="cursor" (click)="updateVersion()">Update now</a>
    </div>
  </div>
}
@if (progressBar) {
  @if (connectionType == "slow-2g" || connectionType == "2g") {
    <app-headline></app-headline>
  }
  @defer {
    <ngx-loading-bar
      [color]="'#006de0'"
      [includeSpinner]="false"
      [height]="'4px'"
    ></ngx-loading-bar>
  }
}
@if (!auth) {
  <router-outlet></router-outlet>
} @else {
  <div class="containerBg">
    <div class="container">
      <div class="row">
        @if ((resize.windowSize$ | async)?.width > 1024) {
          <div class="col-lg-4">
            <div
              class="contentImgBox mt-5 oHideBox dCenter cursor"
              routerLink="/"
            >
              <img
                class="lazyImage"
                width="100%"
                height="100%"
                [useSrcset]="true"
                defaultImage="https://res.cloudinary.com/dmdawjgs8/image/upload/v1701082076/comejoinus/new/brokenimg_1_r0bg15.webp"
                lazyLoad="assets/images/mentogaLogo.png"
                alt="Image"
              />
            </div>
            <div class="leftImgBox">
              <owl-carousel-o [options]="productsOWL">
                <ng-container *ngFor="let item of products">
                  <ng-template carouselSlide [id]="item.id" let-owlItem>
                    <div
                      [@activeSlide]="
                        owlItem.isCentered ? 'active' : 'inActive'
                      "
                    >
                      <h4 class="text-center mt-3">{{ item?.title }}</h4>
                      <h6 class="text-center mt-3 description">
                        {{ item?.description }}
                      </h6>
                      <div class="productImgBox dCenter oHideBox mt-3">
                        <img
                          class="lazyImage"
                          width="100%"
                          height="100%"
                          [useSrcset]="true"
                          defaultImage="https://res.cloudinary.com/dmdawjgs8/image/upload/v1701082076/comejoinus/new/brokenimg_1_r0bg15.webp"
                          [lazyLoad]="item.image"
                          [alt]="item.title"
                        />
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </owl-carousel-o>
            </div>
          </div>
        }
        <div
          class="col-lg-6"
          [ngClass]="{ 'ms-auto': (resize.windowSize$ | async)?.width > 1024 }"
        >
          <div
            [ngClass]="{
              'h-100 d-flex align-items-center justify-content-center':
                (resize.windowSize$ | async)?.width > 1024
            }"
          >
            @if ((resize.windowSize$ | async)?.width <= 1024) {
              <div
                class="contentImgBox mt-3 oHideBox dCenter cursor"
                routerLink="/"
              >
                <img
                  class="lazyImage"
                  width="100%"
                  height="100%"
                  [useSrcset]="true"
                  defaultImage="https://res.cloudinary.com/dmdawjgs8/image/upload/v1701082076/comejoinus/new/brokenimg_1_r0bg15.webp"
                  lazyLoad="assets/images/mentogaLogo.png"
                  alt="Image"
                />
              </div>
            }
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
}
@if (loggedIn && logoutModal) {
  <app-modal modalId="logoutModal" [verticallyCentered]="true">
    <div class="modal-body">
      <div class="logoutmodal py-5 px-3 gilroy text-center">
        <div class="logoutBox mx-auto mb-2 dCenter oHideBox">
          <img
            class="lazyImage"
            width="100%"
            height="100%"
            [useSrcset]="true"
            defaultImage="https://res.cloudinary.com/dmdawjgs8/image/upload/v1701082076/comejoinus/new/brokenimg_1_r0bg15.webp"
            lazyLoad="https://res.cloudinary.com/dmdawjgs8/image/upload/v1700464557/lggdtodrwlz9rz4lycav.webp"
            alt="Image"
          />
        </div>
        <h5 class="my-4 gilroy-sb">
          Are you sure you
          <br />
          you want to logout?
        </h5>
        <div class="row gap-3">
          <div class="col d-grid">
            <button
              (click)="helper.signOut(); helper.showModal(false, 'logoutModal')"
              class="btnPrimary rounded-1 gilroy-sb"
            >
              Yes
            </button>
          </div>
          <div class="col d-grid">
            <button
              class="btnDark rounded-1 gilroy-sb"
              data-bs-dismiss="modal"
              (click)="helper.showModal(false, 'logoutModal')"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </app-modal>
}
@if (loader) {
  <app-loading [loggedIn]="progressBar"></app-loading>
}
