import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit {
  @Input('modalId') modalId: string;
  @Input('modalSize') modalSize: string;
  @Input('verticallyCentered') verticallyCentered: boolean;
  @Input('radius') radius: string;

  constructor() {}

  ngOnInit(): void {}
}
