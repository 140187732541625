import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollData = new BehaviorSubject<{
    scrollPosition: number;
    scrollUp: boolean;
  }>({
    scrollPosition: 0,
    scrollUp: false,
  });

  scrollData$ = this.scrollData.asObservable();

  onScroll(scrollPosition: number, scrollUp: boolean) {
    this.scrollData.next({
      scrollPosition: scrollPosition,
      scrollUp: scrollUp,
    });
  }
}
