import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUser } from '../ngrx/data.reducer';
import { environment } from '../../environments/environment';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  user$ = this.store.select(selectUser);
  userSubscription;
  userData: any;
  private destroy$ = new Subject<void>();
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    this.userSubscription = this.user$
      .pipe(
        takeUntil(this.destroy$), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
      .subscribe((user) => {
        this.userData = user;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  switchUserTypeAsync(
    token: string,
    userTypeId: number,
    userId: number,
    deviceId: string,
  ) {
    let data = {
      userId: userId,
      userTypeId: userTypeId,
      deviceId: deviceId,
    };
    const TypeOf = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
        .set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(
      `${environment.apiUrl}api/user/switch-user-account-type`,
      data,
      TypeOf,
    );
  }
  refreshToken() {
    let data = {
      userID: this.userData.id,
      userName: this.userData.fullName,
      deviceID: this.userData.deviceID,
      email: this.userData.email,
      type: this.userData.userType,
      token: this.userData.token,
      refreshToken: this.userData.refreshToken,
    };
    return this.http.post<any>(
      `${environment.apiUrl}api/Account/refreshToken`,
      data,
    );
  }
}
