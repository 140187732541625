import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

export const activeSlideAnimation: AnimationTriggerMetadata = trigger('activeSlide', [
  state('active', style({
    transform: 'translateX(0)',
    opacity: 1,
  })),
  state('inActive', style({
    transform: 'translateX(100%)',
    opacity: 0,
  })),
  transition('active => inActive', [
    animate('0.7s')
  ]),
  transition('inActive => active', [
    animate('0.7s')
  ])
]);
