import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnlineStatusService {
  private onlineSubject = new BehaviorSubject<boolean>(
    this.isBrowser() ? navigator.onLine : true,
  );
  public onlineStatus$ = this.onlineSubject.asObservable();

  constructor() {
    if (this.isBrowser()) {
      const online$ = fromEvent(window, 'online').pipe(mapTo(true));
      const offline$ = fromEvent(window, 'offline').pipe(mapTo(false));
      merge(online$, offline$, of(navigator.onLine)).subscribe(
        this.onlineSubject,
      );
    }
  }

  get isOnline(): boolean {
    return this.onlineSubject.value;
  }

  private isBrowser(): boolean {
    return typeof window !== 'undefined';
  }
}
